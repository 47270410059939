var options = {
    "animate": true,
    "patternWidth": 600,
    "patternHeight": 600,
    "grainOpacity": 0.15,
    "grainDensity": 1,
    "grainWidth": 0.7,
    "grainHeight": 0.7,
  }
grained("#clicker", options);


let videoElem = document.getElementById('video-nifff')
videoElem.pause()
videoElem.addEventListener('ended',myHandler,false);

function myHandler(e) {
    window.location.href = "https://www.supermafia.com/";
}

let clicker = document.querySelector('#clicker')
clicker.addEventListener('click', () => {
    

    clicker.style = "opacity: 0;"
    videoElem.play()  

})